export const collections = [
	'2-20-24-TokyoMclaren',
	'8-23-24-SubaruWRX',
	'12-5-24-AuroraStars',
	'4-8-24-NICARace',
	'11-1-24-GoldenGateBridge',
	'8-12-24-MeteorShower',
	'20-2-24-Japan-1',
	'31-7-24-MarugameCastle',
	'6-8-24-Sakaide',
	'7-8-24-KyotoDayOne',
	'6-8-24-SakaideFestival',
	'6-8-24-DownJapan',
	'5-8-24-SakaideTrainStation',
	'9-12-24-Porsche928',
];

export const images = {
	'2-20-24-TokyoMclaren': require.context(
		'assets/images/photography/2-20-24-TokyoMclaren/bordered'
	),
	'8-23-24-SubaruWRX': require.context(
		'assets/images/photography/8-23-24-SubaruWRX/bordered'
	),
	'12-5-24-AuroraStars': require.context(
		'assets/images/photography/12-5-24-AuroraStars/bordered'
	),
	'4-8-24-NICARace': require.context(
		'assets/images/photography/4-8-24-NICARace/bordered'
	),
	'11-1-24-GoldenGateBridge': require.context(
		'assets/images/photography/11-1-24-GoldenGateBridge/bordered'
	),
	'8-12-24-MeteorShower': require.context(
		'assets/images/photography/8-12-24-MeteorShower/bordered'
	),
	'20-2-24-Japan-1': require.context(
		'assets/images/photography/20-2-24-Japan-1/bordered'
	),
	'6-8-24-Sakaide': require.context(
		'assets/images/photography/6-8-24-Sakaide/bordered'
	),
	'31-7-24-MarugameCastle': require.context(
		'assets/images/photography/31-7-24-MarugameCastle/bordered'
	),
	'7-8-24-KyotoDayOne': require.context(
		'assets/images/photography/7-8-24-KyotoDayOne/bordered'
	),
	'6-8-24-SakaideFestival': require.context(
		'assets/images/photography/6-8-24-SakaideFestival/bordered'
	),
	'6-8-24-DownJapan': require.context(
		'assets/images/photography/6-8-24-DownJapan/bordered'
	),
	'5-8-24-SakaideTrainStation': require.context(
		'assets/images/photography/5-8-24-SakaideTrainStation/bordered'
	),
	'9-12-24-Porsche928': require.context(
		'assets/images/photography/9-12-24-Porsche928/bordered'
	),
};

export const lowresImages = {
	'2-20-24-TokyoMclaren': require.context(
		'assets/images/photography/2-20-24-TokyoMclaren/lowres'
	),
	'8-23-24-SubaruWRX': require.context(
		'assets/images/photography/8-23-24-SubaruWRX/lowres'
	),
	'12-5-24-AuroraStars': require.context(
		'assets/images/photography/12-5-24-AuroraStars/lowres'
	),
	'4-8-24-NICARace': require.context(
		'assets/images/photography/4-8-24-NICARace/lowres'
	),
	'11-1-24-GoldenGateBridge': require.context(
		'assets/images/photography/11-1-24-GoldenGateBridge/lowres'
	),
	'8-12-24-MeteorShower': require.context(
		'assets/images/photography/8-12-24-MeteorShower/lowres'
	),
	'20-2-24-Japan-1': require.context(
		'assets/images/photography/20-2-24-Japan-1/lowres'
	),
	'6-8-24-Sakaide': require.context(
		'assets/images/photography/6-8-24-Sakaide/lowres'
	),
	'31-7-24-MarugameCastle': require.context(
		'assets/images/photography/31-7-24-MarugameCastle/lowres'
	),
	'7-8-24-KyotoDayOne': require.context(
		'assets/images/photography/7-8-24-KyotoDayOne/lowres'
	),
	'6-8-24-SakaideFestival': require.context(
		'assets/images/photography/6-8-24-SakaideFestival/lowres'
	),
	'6-8-24-DownJapan': require.context(
		'assets/images/photography/6-8-24-DownJapan/lowres'
	),
	'5-8-24-SakaideTrainStation': require.context(
		'assets/images/photography/5-8-24-SakaideTrainStation/lowres'
	),
	'9-12-24-Porsche928': require.context(
		'assets/images/photography/9-12-24-Porsche928/lowres'
	),
};
