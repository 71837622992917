import NotFound from './Components/pages/NotFound';
import Home from './Components/pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from 'Components/containers/Navbar';
import Contact from 'Components/pages/Contact';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import PhotoCollection from 'Components/pages/PhotoCollection';
import Footer from 'Components/containers/Footer';
import PhotoView from 'Components/pages/PhotoView';
import WhoAmI from 'Components/pages/WhoAmI';
import Projects from 'Components/pages/Projects/Projects';

const firebaseConfig = {
	apiKey: 'AIzaSyAp1mFDhwRwbZyBxppitUnKhHFOcgH-yUA',
	authDomain: 'cadenmarinozzi-d87cc.firebaseapp.com',
	projectId: 'cadenmarinozzi-d87cc',
	storageBucket: 'cadenmarinozzi-d87cc.appspot.com',
	messagingSenderId: '577529420320',
	appId: '1:577529420320:web:ee0a309271e12f1c737a45',
	measurementId: 'G-01WGD152T5',
};

const themes = ['light', 'dark', 'red'];

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default () => {
	const theme = themes[Math.floor(Math.random() * themes.length)];

	document.body.classList.add(theme);
	themes.forEach((t) => t !== theme && document.body.classList.remove(t));

	return (
		<main className='bg-background text-text fill-fill w-[100vw] min-h-[100vh] max-h-[100vh] overflow-x-hidden overflow-y-auto'>
			<BrowserRouter>
				<Navbar />
				<Routes>
					<Route path='*' element={<Home />} />
					<Route path='/contact' element={<Contact />} />
					<Route
						path='/photo-collection/:collectionId'
						element={<PhotoCollection />}
					/>
					<Route
						path='/photo-view/:collectionId/:photoId'
						element={<PhotoView />}
					/>
					<Route
						path='/photo-view/:collectionId'
						element={<PhotoCollection />}
					/>
					<Route path='/who-am-i' element={<WhoAmI />} />
					<Route path='/projects' element={<Projects />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		</main>
	);
};
